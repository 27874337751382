<template>
  <div class="table-container">
    <div
      v-cus-loading="loading.main"
      class="inner"
    >
      <table
        class="table-responsive table-bordered table-bordered-rounded "
        width="100% "
        cellspacing="10 "
        cellpadding="10 "
      >
        <thead>
          <tr>
            <th>{{ $tc('type') }}</th>
            <th>{{ $tc('reference') }}</th>
            <th>{{ $tc('status') }}</th>
            <th>{{ $tc('authorization') }}</th>
            <th>{{ $tc('date') }}</th>
            <th>{{ $tc('method') }}</th>
            <th>{{ $tc('franchise') }}</th>
            <th>{{ $tc('franchiseName') }}</th>
            <th>{{ $tc('result') }}</th>
            <th>{{ $tc('paid') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in tables.placeToPay.data"
            :key="key"
          >
            <td :data-table-header="$tc('type')">
              <span>
                <strong>{{ $tc($options.filters.toCamelCase(item.placetoPayRequestType)) }}</strong>
              </span>
            </td>
            <td :data-table-header="$tc('reference')">
              <span>{{ item.placeToPayReference }}</span>
            </td>
            <td :data-table-header="$tc('status')">
              <span
                :class="`text-${toKebabCase(item.placeToPayStatus)}`"
              >{{ $tc(toCamelCase(item.placeToPayStatus)) }}</span>
            </td>
            <td :data-table-header="$tc('authorization')">
              <span
                :class="`text-${toKebabCase(item.placeToPayStatus)}`"
              >{{ item.placeToPayCus || '&nbsp;' }}</span>
            </td>
            <td :data-table-header="$tc('date')">
              {{ item.placeToPayStatusDate | formatToDateTime }}
            </td>
            <td :data-table-header="$tc('method')">
              {{ item.placeToPayMethod || '&nbsp;' }}
            </td>
            <td :data-table-header="$tc('franchise')">
              {{ item.placeToPayFranchise || '&nbsp;' }}
            </td>
            <td :data-table-header="$tc('franchiseName')">
              {{ item.placeToPayFranchiseName || '&nbsp;' }}
            </td>
            <td :data-table-header="$tc('result')">
              <span
                :class="`text-${toKebabCase(item.placeToPayStatus)}`"
              >({{ item.placeToPayStatusReason }}):
                {{ item.placeToPayStatusMessage }}</span>
            </td>
            <td
              align="right"
              :data-table-header="$tc('paid')"
            >
              <div
                v-if="item.placetoPayRequestType.toLowerCase() !== 'subscription'"
              >
                <span
                  :class="`text-${toKebabCase(item.placeToPayStatus)}`"
                >{{ $options.filters.formatToCurrencyWithIsoCode(item.placeToPayAmountPaid) }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table__caption">
      <h2 class="table__header">
        <span>{{ $tc('transaction', 3) }}</span>
      </h2>
      <p class="table__header--sub">
        <span>{{ $tc('effected', 4) }}</span>
      </p>
    </div>
  </div>
</template>
<script>

/* nbxr
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'

/* End of nbxr
-------------------------------------------------- */

export default {
  components: {},
  props: {
    voucherId: {
      required: true,
    },
  },
  data() {
    return {
      models: {},
      tables: {
        placeToPay: {
          data: [],
        },
      },
      loading: {
        main: false,
      },
    }
  },
  methods: {
    ...mapFilters(['toCamelCase', 'toKebabCase']),
    getData() {
      const _this = this
      _this.loading.main = true
      _this.$http
        .get(`payments/placeToPay/${_this.voucherId}/payments`)
        .then(response => {
          _this.tables.placeToPay.data = response.body
        })
        .finally(_ => (_this.loading.main = false))
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/transactions/transaction-result.scss";
</style>
